<template>
  <!-- <div class="px-1">
    <svg version="1.1" class="text-red-600"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
         viewBox="0 0 512 512" xml:space="preserve">
  
        <path style="fill:#2D527C;" d="M464.254,477.404H47.747c-26.326,0-47.743-21.418-47.743-47.743V82.339
          c0-26.326,21.418-47.743,47.743-47.743h416.507c26.326,0,47.743,21.418,47.743,47.743c0,7.641-6.195,13.837-13.837,13.837
          s-13.837-6.196-13.837-13.837c0-11.066-9.003-20.068-20.068-20.068H47.747c-11.066,0-20.068,9.003-20.068,20.068v347.322
          c0,11.066,9.003,20.068,20.068,20.068h416.507c11.066,0,20.068-9.003,20.068-20.068V244.01c0-7.641,6.195-13.837,13.837-13.837
          s13.837,6.196,13.837,13.837v185.65C511.997,455.987,490.579,477.404,464.254,477.404z"/>
  
        <path style="fill:#CEE8FA;" d="M13.843,82.338v69.882h484.318V82.338c0-18.726-15.18-33.906-33.906-33.906H47.748
          C29.024,48.432,13.843,63.613,13.843,82.338z"/>
  
        <g>
          <path style="fill:#2D527C;" d="M498.162,166.057H13.844c-7.642,0-13.837-6.196-13.837-13.837v-69.88
            c0-26.326,21.418-47.743,47.743-47.743h416.507c26.326,0,47.743,21.418,47.743,47.743v69.882
            C512,159.862,505.805,166.057,498.162,166.057z M27.681,138.382h456.643V82.339c0-11.066-9.003-20.068-20.068-20.068H47.75
            c-11.066,0-20.068,9.003-20.068,20.068L27.681,138.382L27.681,138.382z"/>
          <path style="fill:#2D527C;" d="M13.837,166.001c-3.541,0-7.083-1.352-9.784-4.053c-5.404-5.404-5.404-14.165,0-19.57
            L107.778,38.652c5.404-5.402,14.165-5.402,19.57,0c5.404,5.404,5.404,14.165,0,19.57L23.623,161.948
            C20.92,164.65,17.378,166.001,13.837,166.001z"/>
          <path style="fill:#2D527C;" d="M205.195,166.056c-3.541,0-7.082-1.351-9.784-4.053c-5.404-5.402-5.405-14.164,0-19.569
            L299.177,38.653c5.402-5.405,14.164-5.406,19.569-0.001c5.404,5.402,5.405,14.164,0,19.569L214.979,162.001
            C212.278,164.705,208.736,166.056,205.195,166.056z"/>
          <path style="fill:#2D527C;" d="M396.58,166.056c-3.542,0-7.082-1.352-9.784-4.053c-5.404-5.404-5.404-14.165,0-19.57l92.726-92.725
            c5.404-5.402,14.164-5.401,19.569,0c5.404,5.404,5.404,14.165,0,19.57l-92.725,92.725
            C403.664,164.705,400.121,166.056,396.58,166.056z"/>
        </g>
        <polygon style="fill:currentColor;" points="202.73,225.886 336.948,305.518 202.73,385.148 "/>
        <path style="fill:currentColor;" d="M202.73,398.987c-2.362,0-4.724-0.605-6.847-1.814c-4.321-2.46-6.991-7.052-6.991-12.023V225.886
          c0-4.973,2.669-9.563,6.991-12.023c4.319-2.462,9.629-2.416,13.908,0.123l134.217,79.632c4.201,2.493,6.776,7.016,6.776,11.902
          s-2.575,9.408-6.776,11.902l-134.217,79.632C207.615,398.341,205.173,398.987,202.73,398.987z M216.568,250.185v110.665
          l93.262-55.332L216.568,250.185z"/>
    </svg>
  </div> -->
  <svg class="ml-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" width="68px" height="68px" viewBox="0 0 128 128" xml:space="preserve" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <circle style="fill:#E16B5A;" cx="64" cy="64" r="64"></circle> <path style="fill:#D16354;" d="M115.061,102.578L99.605,64.253c-0.028-0.159-0.08-0.314-0.158-0.468 c-0.073-0.522-0.396-1.032-1.004-1.429L45.462,27.644c-1.38-0.904-2.509-0.294-2.509,1.356v0.512v0.512V99v0.512V100l10.796,27.172 C57.088,127.71,60.51,128,64,128C84.855,128,103.376,118.02,115.061,102.578z"></path> <path style="fill:#F5F5F5;" d="M42.953,29c0-1.65,1.129-2.26,2.509-1.356l52.981,34.712c1.38,0.904,1.38,2.384,0,3.289 l-52.981,34.711c-1.38,0.904-2.509,0.295-2.509-1.355V29z"></path> </g> </g></svg>

</template>

<script>
export default {
  name: "svg-play"
}
</script>

<style scoped>

</style>