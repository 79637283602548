<template>
  <div class="border-b flex justify-start items-center mb-2 cursor-pointer">
    <router-link active-class="bg-blue-600 text-white font-bold w-full" :to="{name:'student-courses-content-details', params:{  programId:$route.params.programId, lectureId:material.id }, query:{date:mountedData}}"  class="flex gap-2 p-2 rounded">
      <IconComponent class="w-6 h-6 flex-none text-green-400"/>
      <div class="text-sm">{{ material.title }} </div>
    </router-link>
  </div>
</template>

<script setup>
import SvgParagraph from '@/components/svgs/svg-article.vue'
import SvgLiveVideo from '@/components/svgs/live-video.vue'
import SvgPlay from '@/components/svgs/svg-play.vue'

import { computed, defineProps } from 'vue'

const props = defineProps({
  material : {},
  mountedData: {
    type: String,
    default: ''
  }
})

const IconComponent = computed( () => {
  switch(((props.material || {}).icon || '')){
    case 'video-icon':
      return SvgPlay
    case 'live-class-icon':
      return SvgLiveVideo
    case 'paragraph-icon':
      return SvgParagraph
    default:
      return ''
  }
})

</script>